import { useEffect } from 'react';
import useRestaurant from '@lo/shared/hooks/useRestaurant';
import { connect, isConnected, disconnect, updateAuthData } from '@lo/shared/services/sockets';
import { useAuthToken } from '@lo/shared/store/auth';

const useSocketConnection = () => {
    const restaurant = useRestaurant();
    const token = useAuthToken();

    useEffect(() => {
        if (token === undefined) {
            disconnect();
            return;
        }

        if (isConnected()) {
            updateAuthData(token, restaurant);
        } else {
            connect(token, restaurant);
        }
    }, [restaurant.id, token]);
};

export default useSocketConnection;
