import { RestaurantModel } from '../../models';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import addMinutes from 'date-fns/addMinutes';
import compareAsc from 'date-fns/compareAsc';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import format from 'date-fns/format';
import { TFunction } from 'i18next';
import { WorktimeSlot } from '../../types/worktimeSlotType';

export const getOpenTimeTranslation = (t: TFunction, timezone: string, value?: number): string | undefined => {
    if (!value) {
        return undefined;
    }
    const newTime = addMinutes(utcToZonedTime(new Date(), timezone), value);
    return t('orders.live_orders_settings.restaurant.open_at', { time: format(newTime, 'HH:mm') }) || undefined;
};

export const getActiveTimeSlot = (restaurant: RestaurantModel, toggleType: 'pickup' | 'delivery'): WorktimeSlot | undefined => {
    return restaurant.worktime_slots
        ?.filter((slot) => slot.type === toggleType && !slot.is_open)
        .find((slot) => {
            return (
                differenceInMinutes(
                    utcToZonedTime(new Date(), restaurant.timezone),
                    utcToZonedTime(slot.date_from, restaurant.timezone)
                ) >= 0 &&
                compareAsc(utcToZonedTime(slot.date_to, restaurant.timezone), utcToZonedTime(new Date(), restaurant.timezone)) > 0
            );
        });
};

export const getAllActiveTimeSlots = (
    restaurantData: RestaurantModel,
    toggleType: 'pickup' | 'delivery'
): WorktimeSlot[] | undefined => {
    return restaurantData.worktime_slots?.filter(
        (slot) =>
            slot.type === toggleType &&
            !slot.is_open &&
            compareAsc(
                utcToZonedTime(new Date(), restaurantData.timezone),
                utcToZonedTime(slot.date_from, restaurantData.timezone)
            ) > 0 &&
            compareAsc(
                utcToZonedTime(slot.date_to, restaurantData.timezone),
                utcToZonedTime(new Date(), restaurantData.timezone)
            ) > 0
    );
};

export const getSlotTimeLeft = (worktimeSlot: WorktimeSlot, timezone: string): number | undefined => {
    if (!worktimeSlot.date_to) return undefined;
    return differenceInMinutes(utcToZonedTime(worktimeSlot.date_to, timezone), utcToZonedTime(new Date(), timezone), {
        roundingMethod: 'floor'
    });
};

export enum ReasonsEnum {
    busyKitchen = 'busy_kitchen',
    noCouriers = 'no_couriers',
    somethingElse = 'something_else'
}

export enum DurationOptionsEnum {
    tomorrow = 'tomorrow',
    sixtyMinutes = 'sixty_min',
    twentyMinutes = 'twenty_min'
}

export const reasonOptions = (t: TFunction, restaurantData: RestaurantModel): { title: string; id: ReasonsEnum }[] => {
    if (restaurantData.is_scoober) {
        return [
            { title: t('orders.live_orders_settings.restaurant.reason_busy_kitchen'), id: ReasonsEnum.busyKitchen },
            { title: t('orders.live_orders_settings.restaurant.reason_something_else'), id: ReasonsEnum.somethingElse }
        ];
    }

    return [
        { title: t('orders.live_orders_settings.restaurant.reason_busy_kitchen'), id: ReasonsEnum.busyKitchen },
        { title: t('orders.live_orders_settings.restaurant.reason_no_couriers'), id: ReasonsEnum.noCouriers },
        { title: t('orders.live_orders_settings.restaurant.reason_something_else'), id: ReasonsEnum.somethingElse }
    ];
};

export const durationOptions = (t: TFunction): { title: string; id: DurationOptionsEnum }[] => [
    { title: t('orders.live_orders_settings.restaurant.tomorrow'), id: DurationOptionsEnum.tomorrow },
    { title: '60 min', id: DurationOptionsEnum.sixtyMinutes },
    { title: '20 min', id: DurationOptionsEnum.twentyMinutes }
];

export const durationValues = {
    [DurationOptionsEnum.tomorrow]: undefined,
    [DurationOptionsEnum.sixtyMinutes]: 60,
    [DurationOptionsEnum.twentyMinutes]: 20
};

export const untilTomorrowStatusTitle = (t: TFunction, isRestaurantClosed: boolean): string =>
    isRestaurantClosed
        ? t('orders.live_orders_settings.restaurant.closed_until_tomorrow')
        : t('orders.live_orders_settings.restaurant.paused_until_tomorrow');

export const minutesStatusTitle = (t: TFunction, isRestaurantClosed: boolean, minutes: number): string =>
    isRestaurantClosed
        ? t('orders.live_orders_settings.restaurant.closed_for_minutes', { minutes })
        : t('orders.live_orders_settings.restaurant.paused_for_minutes', { minutes });
