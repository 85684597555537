import React, { PropsWithChildren, useEffect } from 'react';
import { RouteProps, useLocation } from 'react-router-dom';
import Footer from '../../sharable-components/Footer/Footer';
import Header from '../../sharable-components/Header/Header';
import { useOverflowElement } from '../../hooks/useOverflowElement';
import { useSaveRestaurantReference } from './hooks/useSaveRestaurantReference';
import { useWindowSize } from '../../hooks/useWindowSize';
import useRestaurantLanguage from './hooks/useRestaurantLanguage';
import PinPopup from '../../sharable-components/PinPopup/PinPopup';
import NotificationsSidebar from '../../sharable-components/NotificationsSidebar/NotificationsSidebar';
import { markNotificationsAsReadApi } from '@lo/shared/api/notifications';
import { useQueryClient } from '@tanstack/react-query';
import RestaurantSidebar from '../../sharable-components/RestaurantSidebar/RestaurantSidebar';
import Navigation from '../../sharable-components/Navigation/Navigation';
import tkwyLogo from '../../static/images/icon-72.png';
import classes from './PrivateLayout.module.scss';
import { IconButton, SideSheet } from '@jet-pie/react';
import { Close } from '@jet-pie/react/esm/icons';
import Banners from '../../sharable-components/Banners/Banners';
import { useOpenedOrderId } from '@lo/shared/store/orders';
import useNotifications from '@lo/shared/hooks/useNotifications';
import useRestaurant from '@lo/shared/hooks/useRestaurant';
import useSocketConnection from '@lo/shared/hooks/useSocketConnection';
import { useFcm } from './hooks/useFcm';
import useGetFeedbackPopup from '../../hooks/useGetFeedbackPopup';
import useWatchForNewOrders from '@lo/shared/hooks/useWatchForNewOrders';
import { ToastContainer } from 'react-toastify';
import { useToasterBottomOffset } from '@lo/shared/store/toaster';
import { FeatureManagerProvider } from '@lo/shared/contexts/FeatureManagerContext';

const PrivateLayout: React.FC<PropsWithChildren<RouteProps>> = ({ children }) => {
    const queryClient = useQueryClient();
    const openedOrderId = useOpenedOrderId();
    const { isLessThanDesktopWidth, isLessThanTabletWidth } = useWindowSize();
    const toasterBottomOffset = useToasterBottomOffset();
    const restaurant = useRestaurant();
    const { hasUnread } = useNotifications();
    const location = useLocation();
    useFcm();
    useSocketConnection();
    useGetFeedbackPopup();
    useSaveRestaurantReference();
    useWatchForNewOrders();
    useRestaurantLanguage();

    const [menuSideBarIsOpen, toggleMenuSidebar] = useOverflowElement();
    const [notificationsSidebarIsOpen, toggleNotificationsSidebar] = useOverflowElement();
    const [restaurantSidebarIsOpen, toggleRestaurantSidebar] = useOverflowElement();

    const handleNotificationsSidebarClose = () => {
        notificationsSidebarIsOpen &&
            hasUnread &&
            markNotificationsAsReadApi().then((notifications) => {
                queryClient.setQueryData(['notifications', restaurant.id], notifications);
            });
        toggleNotificationsSidebar(!notificationsSidebarIsOpen);
    };

    const handleRestaurantSidebarToggle = () => {
        toggleRestaurantSidebar(!restaurantSidebarIsOpen);
    };

    useEffect(() => {
        toggleMenuSidebar(false);
    }, [location]);

    return (
        <FeatureManagerProvider>
            <ToastContainer
                enableMultiContainer
                containerId="generalNotifications"
                style={{ bottom: isLessThanTabletWidth ? toasterBottomOffset : 0 }}
            />
            <>
                <>
                    <Header
                        openMenuSidebar={() => toggleMenuSidebar(true)}
                        openNotificationsSidebar={() => toggleNotificationsSidebar(true)}
                        openRestaurantSidebar={() => toggleRestaurantSidebar(true)}
                    />

                    <Banners />

                    {isLessThanDesktopWidth && (
                        <SideSheet
                            id="navigationSideSheet"
                            isOpen={menuSideBarIsOpen}
                            onShowSideSheet={() => toggleMenuSidebar(!menuSideBarIsOpen)}
                            orientation="left"
                            width="342px"
                            hideOnOutsideClick
                            hideHeader
                            backdrop
                        >
                            <div className={classes.navigationHeader}>
                                <img src={tkwyLogo} width={28} style={{ display: 'block' }} />
                                <IconButton
                                    onClick={() => toggleMenuSidebar(!menuSideBarIsOpen)}
                                    data-testid="close-menu-sidebar"
                                    icon={<Close />}
                                    variant="ghost-tertiary"
                                    size="x-small"
                                />
                            </div>
                            <Navigation />
                        </SideSheet>
                    )}

                    <NotificationsSidebar isOpen={notificationsSidebarIsOpen} toggleSidebar={handleNotificationsSidebarClose} />
                    <RestaurantSidebar
                        isOpen={restaurantSidebarIsOpen}
                        toggleSidebar={handleRestaurantSidebarToggle}
                        restaurant={restaurant}
                    />
                </>

                <div className={classes.container}>
                    <ToastContainer
                        style={{ position: 'absolute', zIndex: 999 }}
                        limit={2}
                        containerId="orderNotifications"
                        enableMultiContainer
                        position="top-right"
                    />
                    {children}
                </div>

                {!openedOrderId && <Footer restaurant={restaurant} />}
            </>
            <PinPopup />
        </FeatureManagerProvider>
    );
};

export default PrivateLayout;
