import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@lo/shared/store/auth';
import { getLoginUrlApi } from '@lo/shared/api/auth';
import { handleKeycloakCode } from '@lo/shared/services/auth';

export default () => {
    const location = useLocation();
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();
    // To not authenticate again after logout
    const [wasAuthenticated, setWasAuthenticated] = useState(isAuthenticated);

    useEffect(() => {
        if (isAuthenticated || wasAuthenticated) {
            setWasAuthenticated(true);
            return;
        }

        const params = new URLSearchParams(location.search);
        const keycloakCode = params.get('code');
        const issuer = params.get('iss');

        if (keycloakCode) {
            history.replace({ search: '' });
            handleKeycloakCode(keycloakCode, issuer || '');
            return;
        }

        getLoginUrlApi().then((url) => {
            window.location.assign(url);
        });
    }, [isAuthenticated]);

    return { isAuthenticated };
};
