import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CookieBanner from './sharable-components/CookieBanner/CookieBanner';
import { Routes } from './routes/routes';
import { handleOrderRequest, handleLanguageParameter, handleTenant } from './common/js/urlRequest';
import useAppState from './hooks/useAppState';
import BusyModeOnboarding from './sharable-components/Onboarding/BusyModeOnboarding';
import { useIsAuthenticated } from '@lo/shared/store/auth';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const App: React.FC = () => {
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    useAppState();

    useEffect(() => {
        handleOrderRequest(location);
        handleLanguageParameter(location);
        handleTenant(location);
    }, []);

    return (
        <>
            <Routes />
            <CookieBanner />
            {isAuthenticated && <BusyModeOnboarding />}
        </>
    );
};

export default App;
