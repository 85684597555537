import addOrUpdateItemInArray from '../../../helpers/array/addOrUpdateItemInArray';
import { shouldIgnoreOrderUpdate } from '../../../helpers/order/shouldIgnoreOrderUpdate';
import { showOrderUpdateNotification } from '../../../helpers/order/showOrderUpdateNotification';
import { playSound } from '../../../helpers/playSound';
import queryClient from '../../query/queryClient';
import i18n from '../../../localization/i18n';
import { showSuccessToast, showWarningToast } from '../../toaster';
import { OrderModel, RestaurantModel } from '../../../models';
import { OrderData } from '../../../types/orderDataType';
import { RestaurantData } from '../../../types/restaurantDataType';
import { useOrdersStore } from '../../../store/orders';

export default (newOrderData: OrderData) => {
    const state = useOrdersStore.getState();
    const orderWasManuallyUpdated = state.manuallyChangedOrderIds.includes(newOrderData.id);

    const restaurantData = queryClient.getQueryData<RestaurantData>(['restaurant']);
    const restaurant = restaurantData && new RestaurantModel(restaurantData);

    const newOrder = new OrderModel(newOrderData);
    const orders = queryClient.getQueryData<OrderModel[]>(['orders', restaurant?.id]) ?? [];
    const orderDataToUpdate = orders.find((o) => o.id === newOrderData.id);
    const orderToUpdate = orderDataToUpdate && new OrderModel(orderDataToUpdate);

    if (orderToUpdate === undefined || restaurant === undefined || shouldIgnoreOrderUpdate(orderToUpdate, newOrder, restaurant)) {
        return;
    }

    queryClient.setQueryData<OrderData[]>(
        ['orders', restaurant.id],
        addOrUpdateItemInArray<OrderData>(newOrderData, [...orders])
    );
    queryClient.invalidateQueries({ queryKey: ['orders', 'details', newOrderData.id] });

    const needToShowNotification = showOrderUpdateNotification(newOrder, orderToUpdate);

    // Do not play sound if order was updated manually previously
    if (!orderWasManuallyUpdated && needToShowNotification && restaurant) {
        playSound(restaurant.ui_settings.order_update_sound || 'default');
    }

    if (newOrder.is_cancelled) {
        showWarningToast(
            i18n.t('orders.live_orders_messages.main.orderid_has_been_cancelled', { orderId: newOrder.public_reference })
        );
        state.actions.openOrderDetails(newOrder.id);
    } else if (needToShowNotification) {
        showSuccessToast(
            i18n.t('orders.live_orders_messages.main.orderid_has_been_updated', { orderId: newOrder.public_reference }),
            { toastId: `order-${newOrder.id}` } // Providing custom toast id to avoid duplicates
        );
    }

    state.actions.receivedOrderUpdateFromSockets(newOrder.id);
};
