import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { hideToast, showWarningToast } from '@lo/shared/services/toaster';
import { setAppIsActive } from '@lo/shared/services/sockets';
import { useBannersActions } from '@lo/shared/store/banners';

const useOnlineStatus = () => {
    const { t } = useTranslation();
    const { showBanner, hideBanner } = useBannersActions();

    useEffect(() => {
        const onVisibilityChange = () => {
            setAppIsActive(document.visibilityState !== 'hidden');
        };

        document.addEventListener('visibilitychange', onVisibilityChange, false);

        return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    }, []);

    useEffect(() => {
        const handleOnline = () => {
            setAppIsActive(true);
            hideToast('no-internet-connection');
            hideBanner('no-internet-connection');
        };

        const handleOffline = () => {
            setAppIsActive(false);

            showWarningToast(t('orders.live_orders_messages.main.no_internet_connection'), {
                autoClose: false,
                toastId: 'no-internet-connection'
            });

            showBanner('no-internet-connection');
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);
};

export default useOnlineStatus;
