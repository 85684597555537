import axios from 'axios';
import { defineStorageMethod } from '../helpers/defineStorageMethod';
import { ADD_TO_STORAGE, GET_FROM_STORAGE, SESSION_STORAGE } from '../constants';
import config from '../helpers/config';
import { useAuthStore } from '../store/auth';
import { usePinStore } from '../store/pin';

const axiosSetup = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': `application/json`
    }
});

axiosSetup.interceptors.request.use(
    async (axiosConfig) => {
        const token =
            defineStorageMethod(GET_FROM_STORAGE, 'token') || defineStorageMethod(GET_FROM_STORAGE, 'token', '', SESSION_STORAGE);
        const pinToken = usePinStore.getState().token;
        const selectedRestaurantId = defineStorageMethod(GET_FROM_STORAGE, 'selectedRestaurantId');
        const tenant = defineStorageMethod(GET_FROM_STORAGE, 'tenant');

        if (token) {
            axiosConfig.headers['Authorization'] = `Bearer ${token}`;
        }

        if (pinToken) {
            axiosConfig.headers['X-pin-token'] = pinToken;
        }

        if (selectedRestaurantId) {
            axiosConfig.headers['X-Restaurant-Id'] = selectedRestaurantId;
        }

        if (tenant) {
            axiosConfig.headers['X-Tenant'] = tenant;
        }

        return axiosConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosSetup.interceptors.response.use(
    (response) => {
        const refreshedToken = response.headers['x-refreshed-access-token'];

        if (refreshedToken) {
            defineStorageMethod(ADD_TO_STORAGE, 'token', refreshedToken);
            useAuthStore.getState().actions.tokenRefreshed(refreshedToken);
        }

        return response;
    },
    (error) => {
        console.error(error);

        return Promise.reject(error);
    }
);

export default axiosSetup;
