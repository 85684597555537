import Echo from 'laravel-echo';
import io, { Socket } from 'socket.io-client';
import getListenersMap from './getListenersMap';
import queryClient from '../query/queryClient';
import { hideToast, showErrorToast } from '../toaster';
import i18n from '../../localization/i18n';
import config from '../../helpers/config';
import { reloadPage } from '../../helpers/reloadPage';
import getExtraHeaders from './getExtraHeaders';
import { RestaurantModel } from '../../models';

let instance: Echo | null = null;

let appIsActive = true;
let isRefreshingJWT = false;
let isReconnecting = false;
let connectionFailures = 0;

const listenToRestaurantEvents = (restaurant: RestaurantModel) => {
    /*
     * Leave all channels to avoid possible duplication of listeners.
     */
    instance?.leaveAllChannels();

    const listenersMap = getListenersMap(restaurant);

    for (const [channel, event, callback] of listenersMap) {
        instance?.listen(channel, event, callback);
    }
};

const listenToConnectionEvents = (restaurant: RestaurantModel) => {
    const socket = instance?.connector.socket as Socket;

    socket.on('connect', () => {
        if (isReconnecting) {
            /**
             * Socket.io successfully reconnected.
             * Need to refresh all data that could be updated during the downtime.
             */
            queryClient.invalidateQueries();
            isReconnecting = false;
        }

        listenToRestaurantEvents(restaurant);
        hideToast('connection-error');
        connectionFailures = 0;
    });

    socket.on('connect_error', () => {
        connectionFailures++;

        if (connectionFailures === 5) {
            showErrorToast(i18n.t('orders.live_orders_messages.main.please_try_later'), {
                autoClose: false,
                ctaButtonText: i18n.t('orders.live_orders_messages.main.reload_page'),
                onCtaButtonClick: reloadPage,
                toastId: 'connection-error'
            });
        }
    });

    /**
     * This usually happens when trying to subscribe with expired JWT token.
     * We need to get refreshed token from any API call and resubscribe.
     */
    socket.on('subscription_error', async () => {
        /**
         * We get subscription error from every channel we are subscribed to.
         * That's why we need to be sure we handle refresh only once.
         */
        if (isRefreshingJWT) return;

        /**
         * Api call will refresh the JWT token & trigger reconnection.
         */
        isRefreshingJWT = true;

        if (typeof document === 'undefined') {
            isRefreshingJWT = true;
            disconnect();
            instance?.connector.socket.connect();
            isRefreshingJWT = false;
        } else {
            await queryClient.invalidateQueries();
        }

        isRefreshingJWT = false;
    });

    socket.on('disconnect', (reason) => {
        /*
         * We don't want to reconnect in two cases:
         * 1) the app is in the background (reconnection most likely won't be successful. We will reconnect when the app is active again.)
         * 2) the disconnection was caused by the client (logout, etc.)
         */
        if (appIsActive && reason !== 'io client disconnect') {
            isReconnecting = true;

            const randomDelay = Math.random() * (10000 - 3000) + 3000;
            setTimeout(() => instance?.connector.socket.connect(), randomDelay);
        }
    });
};

export const updateAuthData = async (token: string, restaurant: RestaurantModel) => {
    if (!instance) return;

    const previousRestaurantReference = instance.options.auth.headers['X-Restaurant-Id'];

    instance.options.auth.headers = {
        Authorization: `Bearer ${token}`,
        'X-Restaurant-Id': restaurant.reference
    };

    /*
     * If restaurant changed, we need to resubscribe to all channels.
     */
    if (previousRestaurantReference !== restaurant.reference) {
        listenToRestaurantEvents(restaurant);
    }
};

export const connect = async (token: string, restaurant: RestaurantModel) => {
    instance?.disconnect();

    instance = new Echo({
        broadcaster: 'socket.io',
        client: (typeof window !== 'undefined' && window.io) || io,
        host: config.socketHost,
        namespace: '',
        reconnection: false, // We handle reconnection manually
        auth: {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-Restaurant-Id': restaurant.reference
            }
        },
        transports: ['websocket'],
        extraHeaders: await getExtraHeaders()
    });

    listenToConnectionEvents(restaurant);

    return instance;
};

export const isConnected = () => instance && instance.connector.socket.connected;

export const setAppIsActive = (newValue: boolean) => {
    /**
     * If the app became active and the socket is not connected, we need to connect again.
     */
    if (newValue === true && !isConnected()) {
        instance?.connector.socket.connect();
    }

    appIsActive = newValue;
};

export const disconnect = () => instance?.disconnect();
