import axios from '@lo/shared/ajax/axiosSetup';
import { AxiosResponse } from 'axios';

export function getLoginUrlApi() {
    return axios({
        url: `/sso/redirect-url?callback_path=${window.location.origin + window.location.pathname}`,
        method: 'get'
    }).then((response: AxiosResponse<{ url: string }>) => response.data.url);
}

export function getLogoutUrlApi() {
    return axios({
        url: `/sso/logout-redirect-url?callback_path=${window.location.origin}`,
        method: 'get'
    }).then((response: AxiosResponse<{ url: string }>) => response.data.url);
}

export function loginByCodeApi(code: string, issuer?: string) {
    return axios({
        url: `/sso/auth-by-code`,
        method: 'post',
        data: { callback_path: window.location.origin + window.location.pathname, code, issuer }
    }).then((response: AxiosResponse<{ access_token: string }>) => response.data.access_token);
}
