import { getLogoutUrlApi, loginByCodeApi } from '../../api/auth';
import { defineStorageMethod } from '../../helpers/defineStorageMethod';
import { GET_FROM_STORAGE, REMOVE_FROM_STORAGE } from '../../constants';
import { useAuthStore } from '../../store/auth';
import { showErrorToast } from '../toaster';
import i18n from '../../localization/i18n';
import { removeDeviceApi } from '../../api/devices';
import { usePinStore } from '../../store/pin';
import queryClient from '../query/queryClient';
import { reloadPage } from '../../helpers/reloadPage';

export const handleKeycloakCode = async (code: string, issuer?: string) => {
    try {
        useAuthStore.getState().actions.loginStarted();

        const token = await loginByCodeApi(code, issuer);

        await useAuthStore.getState().actions.loginSuccess(token);
    } catch (error) {
        showErrorToast(i18n.t('orders.live_orders_login.main.auth_error'));
        useAuthStore.getState().actions.loginFailed();
    }
};

// eslint-disable-next-line
export const logout = async (_reason = 'unknown') => {
    useAuthStore.getState().actions.logoutStarted();

    const fcmToken = defineStorageMethod(GET_FROM_STORAGE, 'fcmToken');
    if (fcmToken) {
        defineStorageMethod(REMOVE_FROM_STORAGE, 'fcmToken');
        await removeDeviceApi(fcmToken);
    }

    await usePinStore.getState().actions.setToken(null);
    queryClient.clear();
    defineStorageMethod(REMOVE_FROM_STORAGE, 'token');

    try {
        const url = await getLogoutUrlApi();
        window.location.assign(url);
    } catch (error) {
        showErrorToast(i18n.t('orders.live_orders_messages.main.please_try_later'), {
            autoClose: false,
            ctaButtonText: i18n.t('orders.live_orders_messages.main.reload_page'),
            onCtaButtonClick: reloadPage,
            toastId: 'connection-error'
        });
    }
};
