import { Tracker } from './tracker.type';
import { defineStorageMethod } from '../../helpers/defineStorageMethod';
import { GET_FROM_STORAGE } from '../../constants';
import { getBooleanFromString } from '../../helpers/string/getBooleanFromString';
import i18n from '../../localization/i18n';
import config from '../../helpers/config';
import queryClient from '../query/queryClient';
import { RestaurantData } from '../../types/restaurantDataType';
import { OrderData } from '../../types/orderDataType';

type Context = {
    schema: string;
    data: Record<string, any>;
};

const availableCountries = [
    'gb',
    'uk',
    'au',
    'at',
    'be',
    'bg',
    'ca',
    'dk',
    'fr',
    'de',
    'ie',
    'il',
    'it',
    'lu',
    'nl',
    'nz',
    'no',
    'pl',
    'pt',
    'ro',
    'es',
    'ch',
    'us',
    'sk',
    'xx',
    'all'
];

export default class Snowplow implements Tracker {
    private consentIsGiven() {
        return getBooleanFromString(defineStorageMethod(GET_FROM_STORAGE, 'cookieBannerWasSeen'));
    }

    private createGlobalContexts() {
        const environment = config.env === 'production' ? 'prod' : config.env === 'staging' ? 'stag' : 'dev';
        const language = i18n.language?.slice(0, 2) || 'en';
        const version = config.release?.slice(0, 36) || '0.0.0'; // Max allowed length of version is 36 characters
        const restaurantData = queryClient.getQueryData<RestaurantData>(['restaurant']);

        let country = restaurantData?.country_contact_information.code || '';
        country = availableCountries.includes(country) ? country : 'xx';

        const contexts: Context[] = [
            {
                schema: 'iglu:jet/cx_platform/jsonschema/1-1-1',
                data: { language, country, version, environment }
            },
            {
                schema: 'iglu:jet/cx_consent/jsonschema/1-0-0',
                data: { status: ['full'] }
            },
            {
                schema: 'iglu:jet/cx_producer/jsonschema/1-0-1',
                data: { team: 'alpha-team' }
            }
        ];

        if (restaurantData) {
            contexts.push({
                schema: 'iglu:jet.part/cx_partner/jsonschema/1-0-0',
                data: {
                    id: restaurantData.reference,
                    status: restaurantData.is_open ? 'open' : 'closed'
                }
            });
        }

        return contexts;
    }

    private createOrderContext(order: OrderData): Context {
        return {
            schema: 'iglu:jet.part/cx_order/jsonschema/1-0-0',
            data: {
                id: order.public_reference,
                total: order.restaurant_total,
                status: order.status,
                placedDateTime: order.placed_date
            }
        };
    }

    private trackSelfDescribingEvent(schema: string, contexts: Context[] = []) {
        if (!this.consentIsGiven()) return;

        window.analytics_pipeline?.('trackSelfDescribingEvent', {
            event: { schema, data: {} },
            context: [...this.createGlobalContexts(), ...contexts]
        });
        window.analytics_pipeline?.('flushBuffer');
    }

    viewedPage() {
        if (!this.consentIsGiven()) return;

        window.analytics_pipeline?.('trackPageView', { context: this.createGlobalContexts() });
        window.analytics_pipeline?.('flushBuffer');
    }

    confirmedOrder(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_accept/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    movedOrderToInDelivery(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_onItsWay/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    deliveredOrder(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_done/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    movedOrderBackToKitchen(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_backToKitchen/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    changedOrderDuration(type: 'cook' | 'delivery', amount: number, order: OrderData) {
        const timeContext = {
            schema: 'iglu:jet.part/cx_time/jsonschema/1-0-0',
            data: { type, amount }
        };

        this.trackSelfDescribingEvent('iglu:jet.part/order_timeUpdate/jsonschema/1-0-0', [
            this.createOrderContext(order),
            timeContext
        ]);
    }

    updatedConfirmedOrder(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_update/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }
}
