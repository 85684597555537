import { create } from 'zustand';
import { SecureStorage } from '../services/storage';
import { authGuards } from '../enums/authGuardsEnum';
import jwtDecode from 'jwt-decode';

type jwtType = {
    aid: string;
    atyp?: authGuards;
    iss: string;
};

type AuthStoreType = {
    isLoading: boolean;
    isAuthenticated: boolean;
    guard: authGuards;
    token?: string;
    actions: {
        loginStarted: () => void;
        loginSuccess: (token: string, guard?: authGuards) => Promise<void>;
        loginFailed: () => void;
        tokenRefreshed: (token: string) => Promise<void>;
        logoutStarted: () => void;
        logoutSuccess: () => Promise<void>;
    };
};

export const useAuthStore = create<AuthStoreType>((set) => ({
    isLoading: true,
    isAuthenticated: false,
    guard: authGuards.RESTAURANT,
    token: undefined,
    actions: {
        loginStarted: () => set({ isLoading: true }),
        loginSuccess: async (token, guard) => {
            try {
                const { atyp } = jwtDecode<jwtType>(token);
                await SecureStorage.setItem('token', token);
                set({ isLoading: false, isAuthenticated: true, token, guard: guard || atyp || authGuards.RESTAURANT });
            } catch (error) {
                set({ isLoading: false, isAuthenticated: false });
            }
        },
        loginFailed: () => set({ isLoading: false, isAuthenticated: false, token: undefined }),
        tokenRefreshed: async (token) => {
            await SecureStorage.setItem('token', token);
            set({ token });
        },
        logoutStarted: () => set({ isLoading: true }),
        logoutSuccess: async () => {
            await SecureStorage.removeItem('token');
            set({ isLoading: false, isAuthenticated: false, token: undefined });
        }
    }
}));

SecureStorage.getItem('token').then((token) => {
    if (token) {
        useAuthStore.getState().actions.loginSuccess(token);
    } else {
        useAuthStore.getState().actions.loginFailed();
    }
});

/** Hooks */
export const useAuthIsLoading = () => useAuthStore((state) => state.isLoading);
export const useIsAuthenticated = () => useAuthStore((state) => state.isAuthenticated);
export const useAuthToken = () => useAuthStore((state) => state.token);
export const useGuard = () => useAuthStore((state) => state.guard);
export const useIsChainAccount = () => useAuthStore((state) => state.guard === authGuards.CHAIN);
export const useAuthStoreActions = () => useAuthStore((state) => state.actions);
