import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import classes from './Notification.module.scss';
import { history } from '../../../routes/history';
import { AlertCircle, AlertTriangle, CheckCircle, InfoCircle } from '@jet-pie/react/esm/icons';
import { colors } from '../../../common/js/colorTokens';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { Button } from '@jet-pie/react';
import { useOrderNotificationsActions } from '@lo/shared/store/orderNotifications';
import { NotificationLevel } from '@lo/shared/types/notificationType';
import { useOrdersStoreActions } from '@lo/shared/store/orders';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

type NotificationData = {
    id: string;
    title: string;
    message: string;
    type: string;
    level: NotificationLevel;
    actionTitle?: string;
    orderId?: number;
    read?: boolean;
    createdAt?: Date;
};

type NotificationProps = {
    notification: NotificationData;
    moreNotificationsCount?: number;
    compact?: boolean;
    onClose?: () => void;
    onClick?: () => void;
};

const iconSize = {
    width: 16,
    height: 16
};

const icons: { [key in NotificationLevel]: ReactElement } = {
    info: <InfoCircle {...iconSize} fill={colors.alias['content-subdued']} />,
    success: <CheckCircle {...iconSize} fill={colors.alias['support-positive']} />,
    warning: <AlertTriangle {...iconSize} fill={colors.alias['support-warning']} />,
    error: <AlertCircle {...iconSize} fill={colors.alias['support-error']} />
};

const alcoholMoreInfo = (t: TFunction) => (
    <div data-testid="alcohol-notification-more-info">
        <p>{t('orders.live_orders_notifications.alcohol.more_title')}</p>
        <ul style={{ paddingLeft: 24 }}>
            <li>{t('orders.live_orders_notifications.alcohol.more_list_item_0')}</li>
            <li>{t('orders.live_orders_notifications.alcohol.more_list_item_1')}</li>
            <li>{t('orders.live_orders_notifications.alcohol.more_list_item_2')}</li>
            <li>{t('orders.live_orders_notifications.alcohol.more_list_item_3')}</li>
        </ul>
        <p>{t('orders.live_orders_notifications.alcohol.more_footer_text')}</p>
    </div>
);

const Notification: React.FC<NotificationProps> = (props) => {
    const { compact = false, moreNotificationsCount, onClose, onClick, notification } = props;
    const { t } = useTranslation();
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const { readNotification } = useOrderNotificationsActions();
    const { openOrderDetails } = useOrdersStoreActions();
    const isProductUpdateNotification = notification.type === 'ProductStockUpdate';
    const timeDifference = notification.createdAt && differenceInMinutes(new Date(), notification.createdAt);
    const timeLabel =
        timeDifference !== undefined &&
        (timeDifference === 0
            ? t('orders.live_orders_notifications.main.now')
            : timeDifference > 60
            ? timeDifference < 120
                ? t('orders.live_orders_notifications.main.one_hour_ago')
                : t('orders.live_orders_notifications.main.hours_ago', { hours: Math.round(timeDifference / 60) })
            : timeDifference === 1
            ? t('orders.live_orders_notifications.main.one_minute_ago')
            : t('orders.live_orders_notifications.main.minutes_ago', { minutes: timeDifference }));

    const onClickNotification = () => {
        onClick && onClick();
        if (isProductUpdateNotification) {
            history.push('menu');
        }
    };

    const handleNotificationAction = () => {
        switch (notification.type) {
            case 'AlcoholInfo':
                if (showMoreInfo) {
                    readNotification(notification.id);
                    setShowMoreInfo(false);
                } else setShowMoreInfo(true);
                break;
            case 'PrepareOrder':
                readNotification(notification.id);
                notification.orderId && openOrderDetails(notification.orderId);
                onClose && onClose();
                break;
        }
    };

    return (
        <div
            data-testid={`notification-${notification.id}${notification.read ? '' : '-unread'}`}
            className={classNames(classes.container, classes[notification.level], { [classes.compact]: compact })}
            onClick={onClickNotification}
        >
            <div className={classes.notificationHeader}>
                <div className={classes.titleContainer}>
                    {icons[notification.level]}
                    <p data-testid={`notification-title-${props.notification.id}`} className={classes.title}>
                        {notification.title}
                    </p>
                </div>
                {notification.read !== undefined && timeLabel !== undefined && (
                    <div className={classes.timestampContainer}>
                        <p className={classes.timestampLabel}>{timeLabel}</p>
                        {!notification.read && (
                            <div
                                data-testid={`notification-read-indicator-${props.notification.id}`}
                                className={classes.unreadIndicator}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className={classNames(classes.body, { [classes.compact]: compact })}>
                <div
                    data-testid={`notification-message-${props.notification.id}`}
                    className={classes.message}
                    dangerouslySetInnerHTML={{
                        __html: notification.message
                    }}
                />
                {showMoreInfo && notification.type === 'AlcoholInfo' && alcoholMoreInfo(t)}
                {!compact && notification.actionTitle && (
                    <div>
                        <Button
                            data-testid={`notification-action-${props.notification.id}`}
                            onClick={handleNotificationAction}
                            className={classes.actionButton}
                            variant="outline"
                            size="xSmall"
                        >
                            {showMoreInfo ? t('orders.live_orders_notifications.alcohol.confirm') : notification.actionTitle}
                        </Button>
                    </div>
                )}
            </div>
            {moreNotificationsCount !== undefined && moreNotificationsCount > 1 && (
                <div className={classes.moreLink}>
                    {<p>{t('orders.live_orders_notifications.main.more_notifications', { count: moreNotificationsCount })}</p>}
                </div>
            )}
        </div>
    );
};

export default Notification;
