import { useQuery } from '@tanstack/react-query';
import { getRestaurantsApiQuery } from '../api/chains';
import { RestaurantInChainType } from '../types/restaurantInChainType';
import { useIsChainAccount } from '../store/auth';

export default () => {
    const isChainAccount = useIsChainAccount();

    return useQuery<RestaurantInChainType[]>({
        queryKey: ['chainRestaurants'],
        queryFn: () => getRestaurantsApiQuery().then((response) => response.data),
        enabled: isChainAccount,
        staleTime: 1000 * 60 * 60 * 12 // Settling for 12 hours since the data is not expected to change often
    });
};
