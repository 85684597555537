import { RestaurantContacts } from '../types/restaurantContactsType';
import { ReceiptSettings } from '../types/receiptSettingsType';
import { RestaurantSettings } from '../types/restaurantSettingsType';
import { WorktimeSlot } from '../types/worktimeSlotType';
import { UISettingsType } from '../types/uiSettingsType';
import { RestaurantData } from '../types/restaurantDataType';
import {
    COURIER_THEN_RESTAURANT,
    DOORHUB,
    GROCERY_UNIFIED_FLOW,
    NOTIME,
    OWN_DELIVERY,
    SCOOBER,
    SCOOBER_UNIFIED_FLOW,
    STUART,
    THIRD_PARTY_GROCERY_FLOW,
    UNIFIED_POS_FLOW,
    YUSO
} from '../constants';

export default class RestaurantModel {
    readonly id: number;
    readonly reference: string;
    readonly name: string;
    readonly logo: string | null;
    readonly is_open: boolean;
    readonly delivery_enabled: boolean;
    readonly pickup_enabled: boolean;
    readonly delivery_service: string;
    readonly order_flow: string;
    readonly food_preparation_duration: number;
    readonly average_delivery_duration: number;
    readonly timezone: string;
    readonly language: string;
    readonly country_contact_information: RestaurantContacts;
    readonly receipt_settings: ReceiptSettings;
    readonly restaurant_settings: RestaurantSettings;
    readonly worktime_slots: WorktimeSlot[];
    readonly ui_settings: UISettingsType;
    readonly allow_close: boolean;

    constructor(data: RestaurantData) {
        Object.entries(data).forEach(([key, value]) => {
            this[key] = value;
        });
    }

    // Default time pickers
    get can_change_default_cooking_time(): boolean {
        return this.order_flow !== COURIER_THEN_RESTAURANT && (this.is_own_delivery || this.is_3PL);
    }
    get can_change_default_delivery_time(): boolean {
        return this.order_flow !== COURIER_THEN_RESTAURANT && this.is_own_delivery;
    }

    // Can change time
    get can_change_confirmed_time(): boolean {
        return this.delivery_service === OWN_DELIVERY;
    }

    // Delivery service getters
    get is_own_delivery(): boolean {
        return this.delivery_service === OWN_DELIVERY;
    }
    get is_scoober(): boolean {
        return this.delivery_service === SCOOBER;
    }
    get is_3PL(): boolean {
        return [NOTIME, STUART, YUSO, DOORHUB].includes(this.delivery_service) || this.order_flow === THIRD_PARTY_GROCERY_FLOW;
    }

    // Can revert order status
    get can_revert_order_status(): boolean {
        return this.delivery_service === OWN_DELIVERY || this.is_unified_order_flow;
    }

    // Order flow getters
    get is_courier_first(): boolean {
        return this.order_flow === COURIER_THEN_RESTAURANT;
    }
    get is_unified_order_flow(): boolean {
        return [SCOOBER_UNIFIED_FLOW, GROCERY_UNIFIED_FLOW, UNIFIED_POS_FLOW].includes(this.order_flow);
    }
    get is_grocery_unified_flow(): boolean {
        return this.order_flow === GROCERY_UNIFIED_FLOW;
    }
}
